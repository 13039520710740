let $formBlock = $("section.questions-form");

if ($formBlock.length > 0) {
  let $form = $formBlock.find("form");
  let $answers = $form.find('input[type="checkbox"]');
  let $submitMessage = $form.find('.submit-message');
  let $duplicateMessage = $form.find('.duplicate-message');
  let $participants_numbers;
  let $formSlug = $('#form-slug')[0].name;
  $form.find('.wpcf7-form-control.wpcf7-quiz').css({ 'display': 'none' });

  getParticipants($formSlug).done(function(data){
    $participants_numbers = data;  
  });

  $('.wpcf7 input[type="submit"]').on('click', function(e) {
    e.preventDefault();
    let $phoneInput = $('input[name="form-phone"]').val();
    let $number = $phoneInput.replace(" ", "");
      if($number != '' && $participants_numbers.includes($number)) {
        $submitMessage.css({'display': 'none'});
        $form.find('.wpcf7-response-output').css('display', 'none');
        $duplicateMessage.insertAfter($form.find('.wpcf7-response-output'));
        $duplicateMessage.css({'display': 'inline-block'});
      }
      else {
        wpcf7.submit($(this).closest('form')[0]);
        setTimeout(function() {
          getParticipants($formSlug).done(function(data){
            $participants_numbers = data;  
          });
        }, 1000);
      }
  });

  $answers.click(function (e) {
    let $answer = $(this);
    let $selectedAnswer = $answer.attr("value");
    let $question = $answer.attr("name").replace("-ans", "");
    let $questionInput = $('input[name="' + $question + '"]');
    if ($answer[0].checked == false) {
      $questionInput.attr("value", "");
      $questionInput[0].value = '';
    } else {
      $questionInput.attr("value", $selectedAnswer);
      $questionInput[0].value = $selectedAnswer;
    }
  });

  document.addEventListener( 'wpcf7invalid', function( event ) {
    $submitMessage.css({'display': 'none'});
    $form.find('input.wpcf7-quiz').each(function (index) {
        let $input = $(this);
        let $prevAnswer = $(this).attr("value");
        setTimeout(function() {
            if($prevAnswer != undefined) {
                $input.attr('value', $prevAnswer);
                $input[0].value = $prevAnswer;
            }
        },1000);
    });
  }, false );

  document.addEventListener( 'wpcf7submit', function( event ) {
    $duplicateMessage.css({'display': 'none'});
      if($form.attr('data-status') != 'invalid') {
          $form.find('.wpcf7-response-output').css('display', 'none');
          setTimeout(function() {
              $form.find('.wpcf7-response-output').css('display', 'inline-block');
              $submitMessage.insertAfter($form.find('.wpcf7-response-output'));
              $submitMessage.css({'display': 'inline-block'});
          }, 1000);
        }
  }, false );

}

function getParticipants($name) {
  let data = new FormData();
  data.append('action', 'get_participants');
  data.append('name', $name);
  return $.ajax({
      type: 'POST',
      url: stiim_scripts.ajax_url,
      cache: false,
      processData: false,
      contentType: false,
      data: data,
  });
}