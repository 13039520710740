import Glide from "@glidejs/glide";

function initImageSlider(id) {
    var imageGlide = new Glide('#' + id, {
        type: 'carousel',
        startAt: 0,
        perView: 1,
        gap: 0,
        autoplay: 10000,
        animationDuration: 2000,
        animationTimingFunc: 'cubic-bezier(0.465, 0.840, 0.440, 1.000)'
    });

    var media = document.getElementById(id);
    if (media && media.querySelector('.glide__slides').children.length > 1) {
        imageGlide.mount();
    }
    var glideNavigation = media.querySelector('.glide-navigation');
    var arrowNavigation = document.querySelector('.swipe-navigation');

    if (glideNavigation) {
        imageGlide.on('run', function (e) {
            var items = glideNavigation.querySelectorAll('a');
            items.forEach(function (item) {
                item.classList.remove('active');
            });

            items[imageGlide.index].classList.add('active');
        });

        glideNavigation.querySelectorAll('a').forEach(function (link) {
            link.addEventListener('click', function (e) {
                e.preventDefault();
                imageGlide.go('=' + this.getAttribute('data-id'));
            });
        });
    }
    if (arrowNavigation) {
        arrowLeft.addEventListener('click', function (e) {
            e.preventDefault();
            imageGlide.go('<');
        });
        arrowRight.addEventListener('click', function (e) {
            e.preventDefault();
            imageGlide.go('>');
        });
    };
}

window.initImageSlider = initImageSlider;

const slideshows = document.querySelectorAll('.slideshow');
if(slideshows && slideshows.length > 0) {
    function setPos(slideshows) {
        let bottom;
        if(window.innerWidth < 600) {
            bottom = 20;
        }
        else {
            bottom = 40;
        }
        slideshows.forEach((slideshow) => {
            let height = 0;
            let slides = slideshow.querySelectorAll('.glide__slide');
            slides.forEach((slide) => {
                if(slide.querySelector('p.caption').offsetHeight > height) {
                    height = slide.querySelector('p.caption').offsetHeight;
                }
            });
            if(slideshow.querySelector('.glide-navigation') != undefined) {
                slideshow.querySelector('.glide-navigation').style.bottom = height + bottom + 'px';
            }
        });
    }
    setPos(slideshows);
    window.addEventListener('resize', function() {
        setPos(slideshows);
    });
}